.Button_button__iMXR5 {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  font-weight: 700;
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-inset: inset;
  --tw-ring-color: transparent;
  min-width: 12rem
}

.Button_button__iMXR5:disabled {
  cursor: default;
  --tw-bg-opacity: 1;
  background-color: rgba(250, 250, 250, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(211, 212, 213, var(--tw-text-opacity));
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(250, 250, 250, var(--tw-ring-opacity))
}

.Button_wide__04iKa {
  width: 100%
}

.Button_primary__O3Hst {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 159, 217, var(--tw-bg-opacity));
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 159, 217, var(--tw-ring-opacity))
}

.Button_secondary__6d8n_ {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 127, 173, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 127, 173, var(--tw-ring-opacity))
}

.Button_secondary__6d8n_:active {
  --tw-text-opacity: 1;
  color: rgba(103, 109, 115, var(--tw-text-opacity))
}

.Button_brand__m5Loe {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 159, 217, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity))
}

.Button_brand__m5Loe:active {
  --tw-text-opacity: 1;
  color: rgba(103, 109, 115, var(--tw-text-opacity))
}

.Button_tertiary__9ZzAY {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 159, 217, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 159, 217, var(--tw-ring-opacity))
}

.Button_tertiary__9ZzAY:active {
  --tw-text-opacity: 1;
  color: rgba(103, 109, 115, var(--tw-text-opacity))
}

.Button_dark__G6eWa {
  --tw-bg-opacity: 1;
  background-color: rgba(31, 41, 55, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(31, 41, 55, var(--tw-ring-opacity))
}

.Button_dark__G6eWa:active {
  --tw-text-opacity: 1;
  color: rgba(103, 109, 115, var(--tw-text-opacity))
}

.Label_label__hBqdO {
    margin-bottom: 0.5rem;
    display: block;
    font-family: Mark, ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 600;
    --tw-text-opacity: 1;
    color: rgba(47, 48, 51, var(--tw-text-opacity))
}

.Input_input__CD_vq {
  display: block;
  width: 100%;
  border-radius: 0.25rem;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgba(211, 212, 213, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 0.875rem;
  line-height: 1.25rem;
}.Input_input__CD_vq::-moz-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(103, 109, 115, var(--tw-placeholder-opacity));
}.Input_input__CD_vq::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(103, 109, 115, var(--tw-placeholder-opacity));
}.Input_input__CD_vq {
  transition-property: background-color, border-color, color, fill, stroke;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}.Input_input__CD_vq:focus-within {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(234, 246, 250, var(--tw-ring-opacity));
}.Input_input__CD_vq:focus {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(234, 246, 250, var(--tw-ring-opacity));
}@media (min-width: 768px) {.Input_input__CD_vq {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}.Input_input__CD_vq {
  font-size: 16px;
  accent-color: #009FD9;
}

.Input_error__gzMmX {
  --tw-border-opacity: 1;
  border-color: rgba(255, 217, 217, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(254, 242, 242, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(153, 27, 27, var(--tw-text-opacity));
}

.Input_error__gzMmX::-moz-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 176, 176, var(--tw-placeholder-opacity));
}

.Input_error__gzMmX::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(255, 176, 176, var(--tw-placeholder-opacity));
}

.Spinner_spinner__apN0q {
  height: 3rem;
  width: 3rem;
  border-radius: 9999px;
  border-width: 4px;
  --tw-border-opacity: 1;
  border-color: rgba(234, 246, 250, var(--tw-border-opacity));
  border-top-color: #009FD9
}

